import Cookie from "js-cookie";
import Swiper from "swiper/swiper-bundle.min";

var CF = CF || {};

(function($) {
  "use strict";

  CF.initialize = {
    init: function() {
      CF.widgets.init();
      CF.documentOnLoad.init();
      CF.documentOnResize.init();
    }
  };

  CF.widgets = {
    init: function() {
      CF.widgets.scrollToTop();
      CF.widgets.detectBrowsers();
      CF.widgets.masks();
      CF.widgets.fancyBoxInit();
      CF.widgets.mobileNavigation();
      CF.widgets.poorVision();
      $publicationNav.length ? CF.widgets.publicationCarousel() : null;
      $accordionWrapper.length ? CF.widgets.accordions() : null;
      $owlContentCarousel.length ? CF.widgets.owlContentCarousel() : null;
      $owlMainCarousel.length ? CF.widgets.owlMainCarousel() : null;
      $mainEventsList.length ? CF.widgets.mainEventsList() : null;
      $eventsList.length ? CF.widgets.eventsList() : null;
      $affiliateEventsList.length ? CF.widgets.affiliateEventsList() : null;
      $priceAccordion.length ? CF.widgets.priceAccordion() : null;
      $interactiveCounter.length ? CF.widgets.interactiveCounter() : null;
      $museumsList.length ? CF.widgets.museumsList() : null;
      $collectionsList.length ? CF.widgets.collectionsList() : null;
      $collectionNavigation.length ? CF.widgets.collectionNavigation() : null;
      $sub02CollectionsList.length ? CF.widgets.loadSub02Collections() : null;
      $socialsShare.length ? CF.widgets.yandexShare() : null;
    },
    scrollToTop: function() {
      $.fn.scrollToTop = function() {
        var $t = $(this);

        $t.hide().removeAttr("href");

        if ($w.scrollTop() !== 0) {
          $t.fadeIn("show");
        }

        $w.scroll(function() {
          if ($w.scrollTop() === 0) {
            $t.fadeOut("slow");
          } else {
            $t.fadeIn("slow");
          }
        });

        $t.click(function() {
          $("html, body").animate({ scrollTop: 0 }, "slow");
        });
      };
    },
    detectBrowsers: function() {
      var user = detect.parse(navigator.userAgent);
      $h.addClass(user.browser.family);
      $h.addClass("v" + user.browser.version);
    },
    masks: function() {
      $phoneMask.inputmask("+7 999 999-99-99", {
        showMaskOnHover: false,
        showMaskOnFocus: true
      });
    },
    fancyBoxInit: function() {
      $fancy.fancybox({
        helpers: {
          overlay: {
            locked: false
          }
        }
      });
    },
    preLoadImages: function() {
      for (var i = 0; i < arguments.length; i++) {
        $("<img>").attr("src", arguments[i]);
      }
    },
    mobileNavigation: function() {
      var $headerBurger = $(".header-burger");

      var checkClick = false;

      $headerBurger.click(function(e) {
        e.preventDefault();
        $b.addClass("show-mobile-navigation");
        checkClick = true;

        $d.bind("click.show-mobile-navigation", function(e) {
          if (
            !checkClick &&
            $(e.target).closest($(".header-mobile-navigation-wrapper"))
              .length === 0
          ) {
            $b.removeClass("show-mobile-navigation");
            $d.unbind("click.show-mobile-navigation");
          }
          checkClick = false;
        });
      });

      $(".close-navigation").click(function(e) {
        e.preventDefault();
        $b.removeClass("show-mobile-navigation");
        $d.unbind("click.show-mobile-navigation");
        checkClick = false;
      });

      var $showSubNav = $(".show-sub-nav");

      $showSubNav.click(function(e) {
        e.preventDefault();
        var $t = $(this);

        $t.toggleClass("showed");
        $t.next(".sub-nav").slideToggle(300);
      });
    },
    accordions: function() {
      $accordionWrapper.find(".accordion-head.active").each(function() {
        $(this)
          .next(".accordion-content")
          .slideDown(300);
      });

      $accordionWrapper.find(".accordion-head").click(function(e) {
        e.preventDefault();
        var $t = $(this);
        if (!$t.is(".active")) {
          $t.addClass("active");
          $t.next(".accordion-content").slideDown(300);
        } else {
          $t.removeClass("active");
          $t.next(".accordion-content").slideUp(300);
        }
      });
    },
    owlContentCarousel: function() {
      $owlContentCarousel.each(function() {
        var $t = $(this);

        $t.owlCarousel({
          items: 1,
          nav: false,
          dots: true,
          dotsContainer: $t.next().find(".owl-content-carousel-dots")
        });

        $t.next()
          .find(".owl-content-carousel-prev")
          .click(function(e) {
            e.preventDefault();
            $t.trigger("prev.owl.carousel");
          });

        $t.next()
          .find(".owl-content-carousel-next")
          .click(function(e) {
            e.preventDefault();
            $t.trigger("next.owl.carousel");
          });
      });
    },
    owlMainCarousel: function() {
      var loop = $owlMainCarousel.data("loop") || false;
      var autoplay = $owlMainCarousel.data("autoplay") || false;
      var autoplayTimeout = $owlMainCarousel.data("autoplay-timeout") || 5000;

      $owlMainCarousel.owlCarousel({
        items: 1,
        loop: loop,
        dots: true,
        nav: true,
        autoplay: autoplay,
        autoplayTimeout: autoplayTimeout,
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        navText: [
          '<span class="if if-long-arrow-left">',
          '<span class="if if-long-arrow-right"></span>'
        ]
      });
    },
    mainEventsList: function() {
      let $mainEventsLoad = $(".main-events-load");
      let $mainEventsFilters = $(".main-events-filters");

      $mainEventsLoad.click(function(e) {
        e.preventDefault();
        loadMainPosts();
      });

      $mainEventsFilters.click(function(e) {
        e.preventDefault();
        let currentButton = $(e.target);
        let buttons = $(e.currentTarget).children();
        let isActive = currentButton.hasClass('active');
        buttons.each((a, e) => $(e).removeClass('active'));

        if (isActive) {
          $mainEventsLoad.removeData('type');
        } else {
          $mainEventsLoad.data('type', currentButton.data('type'));
          currentButton.addClass('active');
        }

        $mainEventsLoad.data('page', 0);
        loadMainPosts();
      })

      function loadMainPosts() {
        $mainEventsLoad.data('page', ($mainEventsLoad.data("page") + 1));

        $.ajax({
          url: "/load_posts",
          data: $mainEventsLoad.data(),
          error: function() {
            $mainEventsLoad.data('page', ($mainEventsLoad.data("page") - 1));
          }
        });
      }
    },
    eventsList: function() {
      var $eventsLoad = $(".events-load");

      $eventsLoad.click(function(e) {
        e.preventDefault();
        $('.events__wrapper-filter').data('page', $('.events__wrapper-filter').data('page')+1);

        $.ajax({
          url: "/events/filter",
          data: $('.events__wrapper-filter').data(),
          error: function() {
            $('.events__wrapper-filter').data('page', $('.events__wrapper-filter').data('page')-1);
          }
        });
      });
    },
    affiliateEventsList: function() {
      var $affiliateEventsLoad = $(".affiliate-events-load");

      $affiliateEventsLoad.click(function(e) {
        e.preventDefault();

        $('.affiliate-events').data('page', $('.affiliate-events').data('page')+1);

        $.ajax({
          url: "/affiliates/load_posts",
          data: $('.affiliate-events').data(),
          error: function() {
            $('.affiliate-events').data('page', $('.affiliate-events').data('page')-1);
          }
        });
      });
    },
    priceAccordion: function() {
      $priceAccordion.find(".show-hidden").click(function(e) {
        e.preventDefault();
        $(this).toggleClass("active");
        $(this)
          .parent()
          .find(".hidden-content")
          .slideToggle(500);
      });
    },
    interactiveCounter: function() {
      var checkApeears = {};

      $interactiveCounter.each(function(i, el) {
        var $t = $(el);
        $t.attr("data-index", i);
        checkApeears[i] = {
          appeared: false
        };
      });

      $interactiveCounter.bind("appear", function(e) {
        var $el = $(e.currentTarget),
          i = $el.data("index");

        if (checkApeears[i].appeared) {
          return false;
        }

        checkApeears[i].appeared = true;

        $el.find("span").countTo();
      });
    },
    museumsList: function() {
      $museumsList.isotope({
        itemSelector: ".item",
        percentPosition: true,
        layoutMode: "fitRows",
        fitRows: {
          columnWidth: ".sizer"
        }
      });

      $museumsList.imagesLoaded().progress(function() {
        $museumsList.isotope();
      });

      var $departmentsFilters = $(".departments-filters");

      $departmentsFilters.find(".filter-item").click(function(e) {
        e.preventDefault();
        var $t = $(this);

        if (!$t.is(".active")) {
          $departmentsFilters.find(".filter-item").removeClass("active");
          $t.addClass("active");
          $museumsList.isotope({ filter: $t.data("filter") });
        } else {
          $t.removeClass("active");
          $museumsList.isotope({ filter: "*" });
        }
      });
    },
    poorVision: function() {
      function updateIsotops() {
        $museumsList.length ? $museumsList.isotope() : null;
        $mainEventsList.length ? $mainEventsList.isotope() : null;
        $collectionsList.length ? $collectionsList.isotope() : null;
      }

      function clearFontSize() {
        $h.removeClass("pv-font-size-small");
        $h.removeClass("pv-font-size-normal");
        $h.removeClass("pv-font-size-medium");
        $h.removeClass("pv-font-size-large");
        Cookie.remove("pv.fontSize");
      }

      function clearTheme() {
        $h.removeClass("pv-theme-normal");
        $h.removeClass("pv-theme-invert");
        $h.removeClass("pv-theme-blue");
        Cookie.remove("pv.theme");
      }

      $(".version-poor-vision").click(function(e) {
        e.preventDefault();
        $h.addClass("show-poor-vision");
        $h.addClass("pv-font-size-normal");
        $h.addClass("pv-theme-normal");
        Cookie.set("pv.enabled", true, { expires: 7, path: "/" });
        Cookie.set("pv.fontSize", "normal", { expires: 7, path: "/" });
        Cookie.set("pv.theme", "normal", { expires: 7, path: "/" });

        updateIsotops();
      });

      var $fontSizeChange = $(".font-size-change");

      $fontSizeChange.click(function(e) {
        e.preventDefault();
        var $t = $(this),
          size = $(this).data("size");

        clearFontSize();
        $fontSizeChange.removeClass("active");
        $t.addClass("active");

        Cookie.set("pv.fontSize", size, { expires: 7, path: "/" });

        switch (size) {
          case "small":
            $h.addClass("pv-font-size-small");
            break;
          case "normal":
            $h.addClass("pv-font-size-normal");
            break;
          case "medium":
            $h.addClass("pv-font-size-medium");
            break;
          case "large":
            $h.addClass("pv-font-size-large");
            break;
          default:
            $h.addClass("pv-font-size-normal");
            break;
        }

        updateIsotops();
      });

      var $themeChange = $(".theme-change");

      $themeChange.click(function(e) {
        e.preventDefault();
        var $t = $(this),
          theme = $(this).data("theme");

        clearTheme();
        $themeChange.removeClass("active");
        $t.addClass("active");

        Cookie.set("pv.theme", theme, { expires: 7, path: "/" });

        switch (theme) {
          case "normal":
            $h.addClass("pv-theme-normal");
            break;
          case "invert":
            $h.addClass("pv-theme-invert");
            break;
          case "blue":
            $h.addClass("pv-theme-blue");
            break;
          default:
            $h.addClass("pv-theme-normal");
            break;
        }

        updateIsotops();
      });

      $(".normal-version").click(function(e) {
        e.preventDefault();
        $fontSizeChange.removeClass("active");
        $themeChange.removeClass("active");
        $(".font-size-change[data-size=normal]").addClass("active");
        $(".theme-change[data-theme=normal]").addClass("active");

        clearFontSize();
        clearTheme();
        $h.removeClass("show-poor-vision");
        Cookie.remove("pv.enabled");

        updateIsotops();
      });
    },
    collectionsList: function() {
      $collectionsList.isotope({
        itemSelector: ".item",
        percentPosition: true,
        masonry: {
          columnWidth: ".sizer",
          gutter: 20
        }
      });

      $collectionsList.imagesLoaded().progress(function() {
        $collectionsList.isotope();
      });
    },
    collectionNavigation: function() {
      var $showCollectionMenu = $(".show-collection-menu");

      var checkClick = false;

      $showCollectionMenu.click(function(e) {
        e.preventDefault();
        if (!checkClick) {
          $collectionNavigation.slideDown(300);
          checkClick = true;
        }

        $d.bind("click.show-collection-navigation", function(e) {
          if (
            !checkClick &&
            $(e.target).closest($(".collection-navigation")).length === 0
          ) {
            $collectionNavigation.slideUp(300);
            $d.unbind("click.show-collection-navigation");
          }
          checkClick = false;
        });
      });

      var $colShowSubNav = $(".col-show-sub-nav");

      $colShowSubNav.click(function(e) {
        e.preventDefault();
        var $t = $(this);

        $t.toggleClass("showed");
        $t.next(".sub-nav").slideToggle(300);
      });
    },
    loadSub02Collections: function() {
      $(".load-more-sub-02-collections").click(function(e) {
        e.preventDefault();

        /*
         *  Todo get collections items
         * */

        $.ajax({
          url: $(this).data("url"),
          dataType: "script"
        });
      });
    },
    yandexShare: function() {
      var share = Ya.share2($socialsShare.get(0), {
        hooks: {
          onready: function() {
            $socialsShare.find(".ya-share2__list li").each(function() {
              var el = $(this);
              if (el.hasClass("ya-share2__item_service_collections")) {
                el.remove();
              }
              if (el.hasClass("ya-share2__item_service_vkontakte")) {
                el.find("span").remove();
                el.find("a").addClass("if if-vkontakte");
              }
              if (el.hasClass("ya-share2__item_service_odnoklassniki")) {
                el.find("span").remove();
                el.find("a").addClass("if if-odnoklasniki");
              }
            });
          }
        }
      });
    },
    publicationCarousel: function() {
      const swiper = new Swiper(".publication-nav", {
        breakpoints: {
          0: {
            direction: "horizontal",
            slidesPerView: 4,
            spaceBetween: 0
          },
          992: {
            direction: "vertical",
            slidesPerView: "auto",
            spaceBetween: 35
          }
        },
        navigation: {
          nextEl: ".publication-nav-next",
          prevEl: ".publication-nav-prev"
        }
      });
      var year = $publicationNav.data("active");
      // console.log(year);
      var $active = $publicationNav.find(
        '.swiper-slide[data-year="' + year + '"]'
      );
      $active.addClass("active");
      // console.log($active.index());
      swiper.slideTo($active.index(), 300);
      swiper.update();
    }
  };

  CF.documentOnReady = {
    init: function() {
      CF.initialize.init();
      CF.documentOnReady.onReady();
    },
    onReady: function() {
      $toTop.scrollToTop();
      tippy(".tooltip", {
        arrow: true
      });

      var $plsInput = $(".form-control");

      function formControlState($input) {
        if ($input.next(".placeholder").length) {
          if ($input.val()) {
            $input.addClass("filled");
          } else {
            $input.removeClass("filled");
          }
        }
      }

      $plsInput.each(function() {
        formControlState($(this));
      });

      $plsInput.blur(function() {
        formControlState($(this));
      });

      $plsInput.change(function() {
        formControlState($(this));
      });

      if ($w.width() < 991 && !checkTableResponse) {
        $(".common-content")
          .find("table")
          .each(function() {
            var $t = $(this);
            $t.wrap('<div class="table-responsive"></div>');
          });

        checkTableResponse = true;
      }

      var $headerSearch = $(".header-search");

      $(".header-search-button").click(function(e) {
        e.preventDefault();
        $headerSearch.addClass("show");
      });

      $(".close-header-search").click(function(e) {
        e.preventDefault();
        $headerSearch.removeClass("show");
      });
    }
  };

  CF.documentOnLoad = {
    init: function() {
      $w.on("load", function() {});
    }
  };

  CF.documentOnResize = {
    init: function() {
      $w.resize(function() {
        if ($w.width() < 991 && !checkTableResponse) {
          $(".common-content")
            .find("table")
            .each(function() {
              var $t = $(this);
              $t.wrap('<div class="table-responsive"></div>');
            });

          checkTableResponse = true;
        }
      });
    }
  };

  var $w = $(window),
    $d = $(document),
    $h = $("html"),
    $b = $("body"),
    $phoneMask = $(".phone-mask"),
    $accordionWrapper = $(".accordion-wrapper"),
    $toTop = $("#toTop"),
    checkTableResponse = false,
    $owlContentCarousel = $(".owl-content-carousel"),
    $owlMainCarousel = $(".owl-main-carousel"),
    $mainEventsList = $(".main-events-list"),
    $eventsList = $(".events-list"),
    $affiliateEventsList = $(".affiliate-events"),
    $priceAccordion = $(".price-accordion"),
    $museumsList = $(".museums-list"),
    $collectionsList = $(".collections-list"),
    $interactiveCounter = $(".interactive-counter"),
    $collectionNavigation = $(".collection-navigation"),
    $sub02CollectionsList = $(".sub-02-collections-list"),
    $socialsShare = $(".socials-share"),
    $fancy = $(".fancy"),
    $publicationNav = $(".publication-nav");

  $d.ready(CF.documentOnReady.init());
})(jQuery);
