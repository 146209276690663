function isMobile() {
    return $('body').hasClass('device-xs')
}

function isHandheld() {
    return $('body').hasClass('device-sm');
}

function isTablet() {
    return $('body').hasClass('device-md');
}

function isLaptop() {
    return $('body').hasClass('device-lg')
}

function isDesktop() {
    return $('body').hasClass('device-xl')
}

function slideToggleNavs(target,outTarget,event) {
    var checkClick = false;

    target.click(function(e){
        e.preventDefault();
        outTarget.slideDown(300);

        checkClick = true;

        $(document).bind('click.'+event, function (e) {
            if (!checkClick && $(e.target).closest(outTarget).length === 0) {
                outTarget.slideUp(300);
                $(document).unbind('click.'+event);
            }
            checkClick = false;
        });
    });
}

function tabsInit($selector) {
    var tabsNavA = $selector.find('.tabs-nav a'),
        tabContent = $selector.find('.tab-content');

    tabContent.find('.tab-pane').removeClass('active').removeClass('show');
    tabContent.find('.tab-pane').eq(0).addClass('active').addClass('show');

    tabsNavA.click(function (e) {
        e.preventDefault();

        var t = $(this),
            target = $(t.attr('href'));

        tabsNavA.removeClass('active');
        tabsNavA.parent().removeClass('active');
        t.addClass('active');
        t.parent().addClass('active');
        target.parent().find('.active').removeClass('show');

        setTimeout(function () {
            target.parent().find('.active').removeClass('active');
            target.addClass('active');
            setTimeout(function () {
                target.addClass('show');
            },200);
        },200);
    });
}