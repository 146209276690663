$(function () {
    if ($(".event--active").length) {
        $(".event--active").prependTo(".events__filter-message");
        $('.filter__message-btn-reset').addClass('filter__message-btn-reset-active');
        $.each($(".event--active"), function() {
            let date = $(this).data('date');
            let interval = $(this).data('interval');
            let keysArray = Object.keys($(this).data());
            keysArray.splice(keysArray.indexOf('date'),1)
                     .splice(keysArray.indexOf('interval'),1);

            if (interval) {
                $('.events__wrapper-filter').data('start_date', $(this).data('start_date'));
                $('.events__wrapper-filter').data('end_date', $(this).data('end_date'));
            } else if (date) {
                $('.events__wrapper-filter').data(keysArray[0], $(this).data(keysArray[0]));
            }
        });
    }

    $('.filter__message-inner').on('click', function () {
        $('.filter__message-btn-reset').addClass('filter__message-btn-reset-active');
    });

    $('.filter__message-btn-reset').on('click', function (e) {
        e.preventDefault();
        $('.filter__message-btn-reset').removeClass('filter__message-btn-reset-active');
        $('.event').removeClass('event--active')
        $('.events__wrapper-filter').removeData();
        $('.events__wrapper-filter').data('page', 1);
        $('.events__wrapper-filter').data('kindId', []);
        $('.events__wrapper-filter').data('affiliateId', []);
        let url = location.protocol + '//' + location.host + location.pathname
        history.pushState($('.events__wrapper-filter').data(), '', url);

        loadEvents($('.events__wrapper-filter').data());
    });

    $('.event').on('click', function () {
        let element = $(this);
        $('.events__wrapper-filter').data('page', 1);
        $('.filter__message-btn-reset').addClass('filter__message-btn-reset-active');

        if (element.data('date')) {
            dateFilter(element);
        } else if (element.data('interval')) {
            dateIntervalFilter(element);
        } else {
            paramFilter(element);
        }
    });

    function dateFilter(element) {
        element.toggleClass('event--active');
        $(".event--active").prependTo(".events__filter-message");

        let elementData = element.data();
        let keysArray = Object.keys(elementData)
        keysArray.splice(keysArray.indexOf('date'),1)
                 .splice(keysArray.indexOf('interval'),1);
        let key = keysArray[0];

        if (element.hasClass('event--active')) {
            $('.events__wrapper-filter').data(key, element.data(key));
        } else {
            $('.events__wrapper-filter').removeData(key);
        }

        loadEvents($('.events__wrapper-filter').data());
    };

    function paramFilter(element) {
        let tags = $('.filter__message-inner').map(function() { 
            return $(this.children[0]).contents()[0].data.trim() 
        }).get();
        let elementData = element.data();
        let key = Object.keys(elementData)[0];
        let value = element.data(key);
        let elementText
        if (element.hasClass('filter__message-inner')) {
            elementText = $($(element).children()[0]).contents()[0].data.trim();
        } else {
            elementText = element.text();
        };

        if (!tags.includes(elementText)) {
            let newTag = createNewTag();
            newTag.data(key, value);
            newTag.children().first().text(element.text());
        } else {
            $('.filter__message-inner p:contains('+ element.text() +')').parent().toggleClass('event--active');
        }

        $(".event--active").prependTo(".events__filter-message");

        let dataArray = $('.events__wrapper-filter').data(key)
        if (dataArray.includes(value)) {
            dataArray.splice(dataArray.indexOf(value),1);
        } else {
            dataArray.push(value);
        }

        loadEvents($('.events__wrapper-filter').data());
    };

    function dateIntervalFilter(element) {
        if (element.hasClass('event--active')) {
            element.removeClass('event--active');
            $('.events__wrapper-filter').removeData('start_date');
            $('.events__wrapper-filter').removeData('end_date');
        } else {
            $('.event-interval').removeClass('event--active')
            element.addClass('event--active');
            $('.events__wrapper-filter').data('start_date', element.data('start_date'));
            $('.events__wrapper-filter').data('end_date', element.data('end_date'));
        };

        $(".event--active").prependTo(".events__filter-message");
        loadEvents($('.events__wrapper-filter').data());
    };

    function createNewTag() {
        let newTag = $('.filter__message-inner').first().clone({withEventsAndData: true});
        $.each(newTag.data(), function (i) {
            newTag.removeAttr("data-" + i);
        });
        newTag.removeData();
        $('.events__filter-message').append(newTag);
        newTag.addClass('event--active');
        newTag.removeClass('event-interval');
        return newTag;
    };

    $('.datepicker-here').datepicker({
        range: true,
        onSelect: function(fd, date, picker) {
            if (date.length > 1) {
                $('.event-interval').removeClass('event--active');
                $('.events__wrapper-filter').data('start_date', date[0]);
                $('.events__wrapper-filter').data('end_date', date[1]);
                $('.filter__message-btn-reset').addClass('filter__message-btn-reset-active');

                let newTag = createNewTag();
                newTag.data('start_date', date[0]);
                newTag.data('end_date', date[1]);
                newTag.data('interval', true);
                newTag.addClass('event-interval');

                let options = { weekday: 'short', month: 'long', day: 'numeric' }
                newTag.children().first().text(date[0].toLocaleDateString("ru-RU", options) + ' - ' + date[1].toLocaleDateString("ru-RU", options));
                $(".event--active").prependTo(".events__filter-message");

                loadEvents($('.events__wrapper-filter').data());
            } else if (date.length == 0) {
                $('.event-interval').removeClass('event--active');
                $('.events__wrapper-filter').removeData('start_date');
                $('.events__wrapper-filter').removeData('end_date');

                loadEvents($('.events__wrapper-filter').data());
            }
        }
    });

    function loadEvents(data) {
        let url = location.protocol + '//' + location.host + location.pathname + '?' + $.param(data);
        history.pushState(data, '', url);

        $.ajax({
          url: "/events/filter",
          data: data
        });
    };

    $('.box__museum-dropbtn').on('click', function () {
        $('.box__museum-content').toggleClass('box__museum-content-active'),
            $('.box__museum-dropbtn').toggleClass('box__museum-dropbtn-active')
    });
    $('.box__event-dropbtn').on('click', function () {
        $('.box__event-content').toggleClass('box__event-content-active'),
            $('.box__event-dropbtn').toggleClass('box__event-dropbtn-active')
    });
    $('.box__calendar-dropbtn').on('click', function () {
        $('.box__calendar-content').toggleClass('box__calendar-content-active'),
            $('.box__calendar-dropbtn').toggleClass('box__calendar-dropbtn-active')
    });

    var width = 400;
    var filterMessage = document.getElementsByClassName('filter__message-inner');
    for (var i = 0; i < filterMessage.length; i++) {
        width = width + filterMessage[i].offsetWidth;
    }

    $(window).resize(function () {

        if ($(window).width() < 580) {
            $('.events__filter-message').css('width', width + 'px');
            $('.event__filter-scroll').css('overflow-x', 'scroll');
        } else {
            $('.events__filter-message').css('width', '');
            $('.event__filter-scroll').css('overflow-x', '');
        }
    });
});