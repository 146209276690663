// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

import $ from "jquery";
global.$ = $;
global.jQuery = $;

require("jquery-ui");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("../vendor/detect.js");
require("inputmask/dist/jquery.inputmask.bundle.js");
require("@fancyapps/fancybox");
global.tippy = require("../vendor/tippy.min.js");
require("imagesloaded");
require("isotope-layout/dist/isotope.pkgd.min.js");
import "owl.carousel";

require("../src/site/partials/functions.js");
require("../src/site/partials/voiceSearch.js");
require("../src/site/partials/metrika.js");
require("../src/site/app.js");
require("../src/site/main.js");
require("../src/site/datepicker.js");
